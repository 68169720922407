import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        Hi Kate I love you
        !
      </header>
    </div>
  );
}

export default App;
